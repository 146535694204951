<template>
  <loader v-bind="{ loading }">
    <columns>
      <column>
       <page-heading
          heading="New Customer"
          sub-heading="List a new customer within your account."
        />
      </column>
      <column class="is-narrow is-flex is-align-items-center">
        <router-link :to="{ name: 'customers' }">Back</router-link>
      </column>
    </columns>
    <form>
      <columns>
        <column>
          <text-input
            classes="is-medium is-rounded"
            description="The company or natural name of the customer you are listing."
            placeholder="ACME CORP LLC"
            required
            :value="customer.name"
            :error="$root.errors.name"
            @input="updateName">
            <span v-if="customer.is_business">
              Company
            </span>
            Name
          </text-input>
        </column>
        <column class="is-narrow">
          <switch-input
            classes="is-medium is-rounded"
            :value="customer.is_business"
            @input="toggleIsBusiness">
            Is a Business
          </switch-input>
        </column>
        <column class="is-4">
          <text-input
            classes="is-medium is-rounded"
            :value="customer.reference"
            @input="updateReference"
            description="An internal comnpany reference if applicable">
            Reference <small class="has-text-weight-light">(Optional)</small>
          </text-input>
        </column>
      </columns>

      <columns>
        <column>
          <h3 class="title is-3">Address</h3>
        </column>
      </columns>

      <columns>
        <column class="is-2">
          <data-selector
            required
            classes="is-medium is-rounded"
            searchable
            :items="location_types"
            :value="customer.location.type"
            :error="$root.errors['location.type']"
            @input="updateLocationType">
            Type
          </data-selector>
        </column>
      </columns>

      <columns>
        <column>
          <address-fields/>
        </column>
      </columns>

      <columns>
        <column class="is-3">
          <text-input
            description="A descriptive name for this customers address."
            classes="is-medium is-rounded"
            :value="location.name"
            @input="updateLocationName"
            :error="$root.errors['location.name']"
            required>
            Name
          </text-input>
        </column>
        <column class="is-3">
          <text-input
            description="The Legal Billing name for this location if applicable"
            classes="is-medium is-rounded"
            :value="location.legal_entity"
            @input="updateLocationLegalName"
            :error="$root.errors['location.legal_entity']">
            Legal Name <small class="has-text-weight-light">(optional)</small>
          </text-input>
        </column>
        <column class="is-3">
          <text-input
            description="The internal company reference for this location if applicable"
            classes="is-medium is-rounded"
            :value="location.reference"
            @input="updateLocationReference"
            :error="$root.errors['location.reference']">
            Reference <small class="has-text-weight-light">(optional)</small>
          </text-input>
        </column>
        <column class="is-5">
          <data-selector
            tag-class="is-medium is-rounded"
            classes="is-medium is-rounded"
            delete-button-class="is-medium is-rounded is-danger"
            description="A list of branches that can work with this customer."
            multiple
            searchable
            required
            :items="branch_list"
            value-key="id"
            label-key="name"
            :value="location.branches"
            :error="$root.errors['location.branches']"
            @input="updateLocationBranches">
            Branches
          </data-selector>
        </column>
      </columns>
      <columns>
        <column>
          <submit-button
            class="is-medium is-rounded"
            :working="creating"
            :disabled="$root.cant('manage-customers')"
            @submit="createClient">
            Create Customer
          </submit-button>
          <p class="help is-danger" v-if="$root.cant('manage-customers')">
            Please contact your administrator to adjust your
            permissions.</p>
        </column>
      </columns>
    </form>
  </loader>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import AddressFields from '../../components/forms/Address'
export default {

  components: {
    AddressFields
  },

  data: () => ({
    loading: true,
    creating: false,
  }),

  async beforeCreate() {
    await this.$store.dispatch('branch/loadList')
    await this.$store.dispatch('customer/clearCustomer')
    await this.$store.dispatch('customer/loadLocationTypes')
    this.loading = false
  },

  beforeDestroy() {
    this.clearCustomer()
  },

  methods: {
    ...mapMutations('customer', [
      'clearCustomer',
      'updateName',
      'toggleIsBusiness',
      'updateReference'
    ]),
    ...mapMutations('location', [
      'updateLocationName',
      'updateLocationType',
      'updateLocationBranches',
      'updateLocationLegalName',
      'updateLocationReference'
    ]),
    createClient() {
      this.creating = true
      this.$store.dispatch('customer/create', {
        ...this.location
      }).then(() => {
        this.$toast.success('Customer successfully created')
        this.creating = false
        this.$router.push({
          name: 'customers'
        })
      }).catch(() => this.creating = false)
    }
  },

  computed: {
    ...mapGetters('branch', [
      'branch_list'
    ]),
    ...mapGetters('customer', [
      'customer',
      'location_types'
    ]),
    ...mapGetters('location', [
      'location',
    ])
  }

}
</script>